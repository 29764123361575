import { AnimatePresence, domAnimation, LazyMotion, motion } from 'framer-motion';
import { GlobalStyle } from '../styles/globals.style.js';
import { AppProviders } from '../context/index';
import { colors, base } from '../styles/base/_allBase.styles.js';
const { CenterDiv } = base;
import logo from '../assets/logo/BB_Logotype-01.png';
const fadeBack = {
	name: 'Fade Back',
	variants: {
		initial: {
			opacity: 0,
			transition: {
				duration: 1,
			},
		},
		animate: {
			opacity: 1,
			scale: 1,
			transition: {
				duration: 1,
				delay: 1,
			},
		},
		exit: {
			opacity: 0,
			transition: {
				duration: 0.1,
				delay: 0,
			},
		},
	},
	transition: {
		duration: 0.1,
	},
};

function MyApp({ Component, pageProps, router }) {
	return (
		<AppProviders>
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					height: '100vh',
					width: '100vw',
					background: colors.warm_palatte_neutral_light,
				}}
			>
				<CenterDiv>
					<img alt={'Boni Beach Logo'} src={logo} style={{ width: '50vw', height: 'auto' }} />
				</CenterDiv>
				<LazyMotion features={domAnimation}>
					<AnimatePresence initial={true} exitBeforeEnter>
						<motion.div
							key={router.route.concat(fadeBack.name)}
							style={{
								display: 'flex',
								position: 'absolute',
								height: '100%',
								width: '100vw',
							}}
							initial='initial'
							animate='animate'
							exit='exit'
							variants={fadeBack.variants}
						>
							<Component {...pageProps} />
						</motion.div>
					</AnimatePresence>
				</LazyMotion>
			</div>
			<GlobalStyle />
		</AppProviders>
	);
}

export default MyApp;

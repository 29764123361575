import * as React from 'react';
import { ThemeProvider } from 'styled-components';
import { theme } from '../styles/base/_allBase.styles';
import { MenuProvider } from './menu.context';

function AppProviders({ children }) {
	return (
		<ThemeProvider theme={theme}>
			<MenuProvider>{children}</MenuProvider>
		</ThemeProvider>
	);
}

export { AppProviders };

import * as React from 'react';

const MenuContext = React.createContext();
MenuContext.displayName = 'MenuContext';

function MenuProvider(props) {
	const [isOpen, setOpen] = React.useState(false);

	function toggle() {
		const newValue = !isOpen;

		setOpen(newValue);
	}

	const value = { isOpen, toggle };

	return <MenuContext.Provider value={value} {...props} />;
}

function useMenu() {
	const context = React.useContext(MenuContext);
	if (context === undefined) {
		throw new Error(`useMenu must be used within a MenuProvider`);
	}
	return context;
}

export { MenuProvider, useMenu };

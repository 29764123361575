import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
html {
  ${'' /* This defines 1rem */}
  font-size: 62.5%;
  
}
body {
  
  font-family: 'NimbusSans', sans-serif;
  font-weight: 200;
  padding: 0rem;
  margin: 0px 0px;
  box-sizing: border-box;
}

a{
  text-decoration: none;
  color: black;
}
*{
  box-sizing: inherit;
  font-weight: 200;
}

@font-face {
    font-family: 'Editorial New';
    src: url('/fonts/EditorialNew-Ultralight.eot');
    src: url('/fonts/EditorialNew-Ultralight.eot?#iefix') format('embedded-opentype'),
        url('/fonts/EditorialNew-Ultralight.woff2') format('woff2'),
        url('/fonts/EditorialNew-Ultralight.woff') format('woff'),
        url('/fonts/EditorialNew-Ultralight.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'NimbusSans';
    src: url('/fonts/NimbusSansLight.eot');
    src: url('/fonts/NimbusSansLight.eot?#iefix') format('embedded-opentype'),
        url('/fonts/NimbusSansLight.woff2') format('woff2'),
        url('/fonts/NimbusSansLight.woff') format('woff'),
        url('/fonts/NimbusSansLight.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
	font-family: 'Whyte-Bold';
	src: url('/fonts/Whyte-Bold.eot');
	src: url('/fonts/Whyte-Bold.eot?#iefix') format('embedded-opentype'), url('/fonts/Whyte-Bold.woff2') format('woff2'), url('/fonts/Whyte-Bold.woff') format('woff'), url('/fonts/Whyte-Bold.ttf') format('truetype');
	font-weight: 400;
	font-style: normal;
	font-display: swap;
}

`;

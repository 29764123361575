import styled from 'styled-components';
import { motion } from 'framer-motion';
import Img from "next/legacy/image";
import * as breakpoints from '../abstracts/_breakpoints';
import * as colors from '../abstracts/_variables.styles';
import * as typo from '../abstracts/_typography.styles';

const { xxSmallDevices, xxSmallDevicesLandscape, extraSmallDevices, extraSmallDevicesLandscape, mediumDevices, mediumDevicesLandscape, largeDevices, extraLargeDevices, xxLargeDevices } = breakpoints;

export const CardHeadingCenterDiv = styled(motion.div)`
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	align-items: center;
	padding: 0rem 0rem;
	height: 100%;
	width: 80%;
	@media ${xxSmallDevices} {
		height: auto;
		padding: 2rem 2rem;
	}
	@media ${extraSmallDevices} {
		height: 100%;
	}
	@media ${mediumDevices} {
		height: 100%;
		justify-content: center;
	}
	@media ${largeDevices} {
		height: 100%;
		justify-content: center;
	}
	@media ${extraLargeDevices} {
		height: 100%;
		justify-content: center;
	}
	@media ${xxLargeDevices} {
		height: 100%;
		justify-content: center;
	}
`;

export const CenterLogoWrapper = styled(motion.div)`
	position: absolute;
	width: 10rem;
	height: 10rem;
	top: calc(50% - 15rem);
	left: calc(50% - 5rem);
	@media ${xxSmallDevices} {
	}
	@media ${xxSmallDevicesLandscape} {
	}
	@media ${extraSmallDevices} {
	}
	@media ${extraSmallDevicesLandscape} {
	}
	@media ${mediumDevices} {
	}
	@media ${mediumDevicesLandscape} {
	}
	@media ${largeDevices} {
	}
	@media ${extraLargeDevices} {
		width: 20rem;
		height: 20rem;
		top: calc(50% - 10rem);
		left: calc(50% - 10rem);
	}
	@media ${xxLargeDevices} {
	}
`;

export const LargeOverlayLogoWrapper = styled(motion.div)`
	position: absolute;
	top: calc(50% - 37.5vh);
	left: 15vw;
	width: 70vw;
	height: 25vh;
	z-index: 2;
	@media ${xxSmallDevices} {
	}
	@media ${xxSmallDevicesLandscape} {
	}
	@media ${extraSmallDevices} {
	}
	@media ${extraSmallDevicesLandscape} {
	}
	@media ${mediumDevices} {
		left: 15vw;
		width: 70vw;
		height: 30vh;
	}
	@media ${mediumDevicesLandscape} {
	}
	@media ${largeDevices} {
	}
	@media ${extraLargeDevices} {
		top: calc(50% - 27rem);
		left: calc(50% - 46rem);
		width: 92rem;
		height: 54rem;
	}
	@media ${xxLargeDevices} {
	}
`;

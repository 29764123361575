// @MEDIA BREAKPOINTS
export const xxSmallDevices = `only screen and (min-width: 300px) and (orientation: portrait)`;
export const xxSmallDevicesLandscape = `only screen and (device-height : 375px) and (device-width : 812px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: landscape)`;
export const extraSmallDevices = `only screen and (min-width: 376px) and (orientation: portrait)`;
export const extraSmallDevicesLandscape = `only screen and (max-width: 800px) and (orientation: landscape)`;
export const mediumDevices = `only screen and (min-width: 768px) and (orientation: portrait)`;
export const mediumDevicesLandscape = `only screen and (min-width: 813px) and (orientation: landscape)`;
export const largeDevices = `only screen and (min-width: 1024px)`;
export const extraLargeDevices = `only screen and (min-width: 1200px)`;
export const xxLargeDevices = `only screen and (min-width: 1300px)`;

//////// CSS
// @media ${xxSmallDevices} {}
// @media ${xxSmallDevicesLandscape} {}
// @media ${extraSmallDevices} {}
// @media ${extraSmallDevicesLandscape} {}
// @media ${mediumDevices} {}
// @media ${mediumDevicesLandscape} {}
// @media ${largeDevices} {}
// @media ${extraLargeDevices} {}
// @media ${xxLargeDevices} {}

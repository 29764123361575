import styled from 'styled-components';
import { motion, AnimatePresence, useCycle, useScroll, useTransform, useSpring, useInView, useMotionValue } from 'framer-motion';
import Img from "next/legacy/image";
import * as containers from './_containers.styles';
import * as breakpoints from '../abstracts/_breakpoints';
import * as colors from '../abstracts/_variables.styles';
import * as typo from '../abstracts/_typography.styles';
import * as base from './_base.styles';
import * as positioning from './_positioning';
import * as imageStyles from './_images';
import * as animations from './_animations.styles';
import { theme } from '../abstracts/_variables.styles';

export {
	base,
	breakpoints,
	colors,
	containers,
	typo,
	positioning,
	imageStyles,
	animations,
	theme,
	styled,
	motion,
	AnimatePresence,
	useCycle,
	useScroll,
	useSpring,
	useInView,
	useTransform,
	useMotionValue,
	Img,
};

import React from 'react';
import { css } from 'styled-components';
////// Colour Palettes //////
//////// Warm Colour Palette
export const warm_palatte_primary = '#2E3D56';
export const warm_palatte_secondary = '#D0BDA8';
export const warm_palatte_tertiary = '#F1F1E6';
export const warm_palatte_neutral_light = '#E4DCD3';
export const warm_palatte_contrast = '#8D5946';
//////// Cool Colour Palette
export const cool_palatte_secondary = '';
export const cool_palatte_primary = '';
export const cool_palatte_tertiary = '';
export const cool_palatte_neutral_light = '';
export const cool_palatte_contrast = '';
//////// Nuetral Colours
export const neutral_palatte_black = '#010101';
export const neutral_palatte_grey_dark = '#7B7D7D';
export const neutral_palatte_grey_light = '#D0D3D4';
export const neutral_palatte_white = '#FFFFFF';
//////// Colors by position
export const background_color = '#FFFFFF';
export const main_font_color = '#010101';
export const font_white = '#FFFFFF';
export const font_black = '#010101';

export const bg_color = css`
	background: ${(props) =>
		props.bg === 'primary'
			? warm_palatte_primary
			: props.bg === 'secondary'
			? warm_palatte_secondary
			: props.bg === 'tertiary'
			? warm_palatte_tertiary
			: props.bg === 'neutral'
			? warm_palatte_neutral_light
			: props.bg === 'contrast'
			? warm_palatte_contrast
			: 'white'};
`;

export const theme = {
	colors: {
		primary: warm_palatte_primary,
		secondary: warm_palatte_secondary,
		tertiary: warm_palatte_tertiary,
		neutral: warm_palatte_neutral_light,
		contrast: warm_palatte_contrast,
		background: background_color,
		font_primary: main_font_color,
		font_black: font_black,
		font_white: font_white,
	},
};
